<template>
	<b-overlay :show="loading" rounded="sm">
		<b-row>
			<b-col cols="12">
				<!-- 1 -->
				<b-card no-body class="mb-4">
					<!-- Search Control -->
					<b-card-body class="p-3 d-flex justify-content-between">
						<b-form-group class="mb-2 w-25">
							<b-input-group>
								<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

								<b-input-group-append>
									<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-card-body>
					<!-- End Section -->

					<!-- Data Table -->
					<b-table
						style="min-height: 250px;"
						show-empty
						responsive
						small
						class="mb-0"
						head-variant="light"
						:items="customers"
						:filter="filter"
						:fields="fields"
						hover
					>
						<template #cell(id)="data"> #{{ data.item.id }} </template>
						<template #cell(isActive)="data">
							<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
							<b-badge variant="danger" v-else>Inactive</b-badge>
						</template>
						<template #cell(progress)="data">
							<b-progress :value="checkProgress(data.item)" show-value height="12px" style="width: 120px;" class="bg-light-secondary"></b-progress>
						</template>
						<template #cell(action)="data">
							<div class="d-flex">
								<b-button variant="outline-primary" size="sm" pill class="d-flex align-items-center mr-2" @click="showModal(data.item)">
									<feather type="search" class="feather-sm"></feather>
									<span class="ml-1">Edit Amount</span>
								</b-button>
							</div>
						</template>
					</b-table>
					<!-- End Section -->

					<!-- Pagination -->
					<div class="p-3 d-flex align-items-center">
						<b-form-group
							label="Per page"
							label-for="per-page-select"
							label-cols-sm="6"
							label-cols-md="4"
							label-cols-lg="9"
							label-size="sm"
							label-class="fw-medium"
							class="mb-0"
						>
							<b-form-select @change="getData" id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
						</b-form-group>
						<div class="ml-auto">
							<b-pagination @input="getData" v-model="currentPage" :total-rows="totalCustomers" :per-page="perPage" align="fill" class="my-0"></b-pagination>
						</div>
					</div>
					<!-- End Section -->
				</b-card>
			</b-col>
		</b-row>

		<!-- Modal -->
		<b-modal id="modal-view" centered title="Details" v-model="show" @hide="show = false" hide-footer>
			<div class="pb-2" v-if="customerSelected">
				<h4 class="mail-desc d-block">{{ customerSelected.customerName }}</h4>
			</div>
			<b-form @submit.prevent="saveAmount">
				<b-form-group label="Amount" label-class="fw-medium mb-1" label-for="amount">
					<b-form-input id="amount" type="number" placeholder="0.00" required v-model="amount" autocomplete="off"></b-form-input>
				</b-form-group>

				<b-button block :disabled="loadingSave" variant="primary" type="submit">
					<b-spinner small v-if="loadingSave"></b-spinner>
					<span v-else>Save</span>
				</b-button>
			</b-form>
		</b-modal>
	</b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "applications",

	data: () => ({
		page: {
			title: "applications",
		},
		fields: [
			{
				key: "id",
				sortable: true,
			},
			{
				key: "customerName",
				label: "Customer Name",
				sortable: true,
			},
			{
				key: "identificationType",
				label: "ID Type",
				sortable: true,
			},
			{
				key: "identificationValue",
				label: "ID Value",
				sortable: true,
			},
			{
				key: "action",
				label: "Action",
				sortable: false,
			},
		],
		loading: false,
		loadingSave: false,
		amount: null,
		filter: null,
		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		customerSelected: null,
		show: false,
	}),
	components: {},
	methods: {
		async getData() {
			this.loading = true;
			await this.$store.dispatch("screening/get_screeningByBankId", 1);
			this.loading = false;
		},
		showModal(item) {
			this.customerSelected = item;
			this.show = true;
		},
		async saveAmount() {
			this.loadingSave = true;
			let data = {
				Id: this.customerSelected.id,
				GUID: this.customerSelected.guid,
				Amount: this.amount,
				ModifiedBy: 1,
			};

			await this.$store.dispatch("screening/update_fromVerificationForm", data);
			await this.getData();
			this.amount = null;
			this.show = false;
			this.loadingSave = false;
		},
		clearData() {
			this.customerSelected = null;
		},
	},
	computed: {
		...mapGetters({
			customers: "screening/getScreeningByBank",
			totalCustomers: "screening/getTotalScreeningByBank",
		}),
	},
	mounted() {},
	created() {
		this.getData();
	},
};
</script>
